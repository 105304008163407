(function () {
	'use strict';
	angular
		.module('settings')
		.controller('SettingsCtrl', [
			'$scope',
			'$rootScope',
			'$location',
			'$timeout',
			'utilities',
			'dataStore',
			'seedcodeCalendar',
			'manageConfig',
			'manageResources',
			'manageGridTimes',
			'manageTheme',
			'daybackIO',
			'firebaseIO',
			'hash',
			'environment',
			'groupSelector',
			SettingsCtrl,
		]);

	function SettingsCtrl(
		$scope,
		$rootScope,
		$location,
		$timeout,
		utilities,
		dataStore,
		seedcodeCalendar,
		manageConfig,
		manageResources,
		manageGridTimes,
		manageTheme,
		daybackIO,
		firebaseIO,
		hash,
		environment,
		groupSelector
	) {
		//Load config from our model and watch for future updates
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');
		// $scope.$on('config', function() {
		// 	$scope.calendar.config = seedcodeCalendar.get('config');
		// });

		$scope.calendar.timezones = utilities.getTimezones();

		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';

		$scope.timeList = utilities.generateTimeList(
			seedcodeCalendar.get('config').timeFormat
		);

		//change Group Popover
		$scope.calendar.user = seedcodeCalendar.get('user');
		$scope.changeGroupPopover = groupSelector.changeGroupPopover;

		$scope.applyLocalSetting = function (
			setting,
			preventFetching,
			preventInit
		) {
			var delay = environment.isMobileDevice ? 400 : 0;
			$timeout(function () {
				manageConfig.applyLocalSetting(
					setting,
					preventFetching,
					preventInit
				);
			}, delay);
		};

		$scope.getSettingsOptions = manageConfig.getSettingsOptions;

		$scope.saveState = function (setting, value) {
			var configMap = seedcodeCalendar.calendar.configMap;
			var saveID = configMap[setting].saved;
			dataStore.saveState(saveID, value);
		};

		$scope.updateTheme = function (theme) {
			manageTheme.applyThemeSetting(theme, true);
		};

		$scope.updateTime = function (reset) {
			var config = seedcodeCalendar.get('config');
			if (reset) {
				manageGridTimes.reset();
			} else {
				manageGridTimes.init();
			}
		};

		$scope.adminSettings = function () {
			$location.path('/settings');
		};

		$scope.sourceSettings = function () {
			$location.path('/source-settings');
		};

		$scope.accountSettings = function (e, runCheckForUpdate) {
			var scopeCallbackFunction = runCheckForUpdate
				? 'checkForUpdate'
				: null;
			var popover = {
				controller: 'ActivationCtrl',
				target: e.currentTarget,
				container: $('body'),
				type: 'modal', // modal or popover
				width: 700,
				// positionX: e.pageX,
				// positionY: e.pageY,
				scopeCallback: scopeCallbackFunction,
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/activation/admin-settings.html\'"></div>'
			);
		};

		$scope.logout = function () {
			daybackIO.signOut(null, null, true);
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};
	}
})();
